<template>
  <div class="wrapper">
    <section class="main">
      <header>
        <img class="header-logo" src="./assets/header/logo-desktop.svg"/>
        <a class="header-request" href="https://app.phyera.com" target="_blank">
          <span>Demo</span>
          <img src="./assets/gfx/arrow.svg" class="request-arrow">
          <!--<img class="header-request" src="./assets/header/request-desktop.svg"/>-->
        </a>
      </header>
      <div class="main-text">
        <h1>Between digital</h1>
        <h1>and physical world</h1>
        <h2>Mining + SaaS + WEB3 + Marketplace</h2>
      </div>
    </section>
    <section class="whatwedo">
      <h1>What We Do</h1>
      <h2>NFT as a certificate of equipment ownership</h2>
      <div class="perks-row perks-row-groupof4">
        <div class="perks-group-of2">
          <div id="perk1" class="perk perk-dark">
            <span class="perk-title"><span class="perk-title-accent">Full automation</span><br>buy / setup / mine / start / stop / sell</span>
          </div>
          <div id="perk2" class="perk perk-dark">
            <span class="perk-title"><span class="perk-title-accent">Lifetime warranty</span><br>Repairs & spare parts covered</span>
          </div>
        </div>
        <div class="perks-group-of2">
          <div id="perk3" class="perk perk-dark">
            <span class="perk-title"><span class="perk-title-accent">Automatic Failover</span><br>Stable Hash Rate Insurance</span>
          </div>
          <div id="perk4" class="perk perk-dark">
            <span class="perk-title"><span class="perk-title-accent">Marketplace</span><br>Easy exit any time</span>
          </div>
        </div>
      </div>
      <h1 class="h1grey">Geography of your sales</h1>
      <h2>All over the world</h2>
      <div class="perks-row perks-row-groupof4">
        <div class="perks-group-of2">
          <div id="perk5" class="perk perk-color">
            <span class="perk-title"><span class="perk-title-accent">Reduce transaction time</span><br>from 3 days to 5 minutes</span>
            <!--<img src="./assets/what-we-do/arrow.svg" class="perk-arrow">-->
          </div>
          <div id="perk6" class="perk perk-color">
            <span class="perk-title">Easy and safe<span class="perk-title-accent"> entry into the business for newbies</span> + new money for the market</span>
            <!--<img src="./assets/what-we-do/arrow.svg" class="perk-arrow">-->
          </div>
        </div>
        <div class="perks-group-of2">
          <div id="perk7" class="perk perk-color">
            <span class="perk-title"><span class="perk-title-accent">Worldwide ownership</span><br>of equipment</span>
            <!--<img src="./assets/what-we-do/arrow.svg" class="perk-arrow">-->
          </div>
          <div id="perk5" class="perk perk-color">
            <span class="perk-title">NFT - as collateral for the lending</span>
            <!--<img src="./assets/what-we-do/arrow.svg" class="perk-arrow">-->
          </div>
        </div>
      </div>
    </section>
    <section class="whatisphyera">
      <span class="answer">
        Phyera mission<span class="answer-accent">
          is making digital ownership of physical assets accessible to all.<p></p>
        </span>
      </span>
    </section>
    <!--<section class="team">
      <h1>The Team</h1>
      <h2>15+ years experience working as blockchain technology</h2>
      <div class="team-roster">
        <div class="roster-row row-tilted-left">
          <div class="person-card">
            <img src="./assets/team/desktop/salomatov.png" class="person-img">
            <div class="person-data">
              <p class="person-title">Alexander Salomatov</p>
              <p class="person-function">CEO & Founder</p>
              <div class="person-bg">
                <span class="person-text"><span class="person-text-accent">Experience: 12+ years</span></span>
                <span class="person-text"><span class="person-text-accent">Sector: </span>Asset Management & Technology, Marketing Development, Finance</span>
                <span class="person-text"><span class="person-text-accent">Background: </span>Mining, GameFi, Serial Entrepreneur</span>
              </div>
            </div>
          </div>
          <div class="person-card">
            <img src="./assets/team/desktop/sipakov.png" class="person-img">
            <div class="person-data">
              <p class="person-title">Alex Sipakov</p>
              <p class="person-function">CBDO & Co-Founder</p>
              <div class="person-bg">
                <span class="person-text"><span class="person-text-accent">Experience: 7+ years</span></span>
                <span class="person-text"><span class="person-text-accent">Sector: </span>Asset Management & Brokerage, IT</span>
                <span class="person-text"><span class="person-text-accent">Background: </span>DeFi, Crypto, IT & Finance</span>
              </div>
            </div>
          </div>
        </div>
        <div class="roster-row row-tilted-right">
          <div class="person-card">
            <img src="./assets/team/desktop/vinogradov.png" class="person-img">
            <div class="person-data">
              <p class="person-title">Roman Vinogradov</p>
              <p class="person-function">CTO & Co-Founder</p>
              <div class="person-bg">
                <span class="person-text"><span class="person-text-accent">Experience: 15+ years</span></span>
                <span class="person-text"><span class="person-text-accent">Sector: </span>Technology & Backend Development, Finance & Trading</span>
                <span class="person-text"><span class="person-text-accent">Background: </span>DeFi, Crypto, IT & Software Development</span>
              </div>
            </div>
          </div>
          <div class="person-card">
            <img src="./assets/team/desktop/perminov.png" class="person-img">
            <div class="person-data">
              <p class="person-title">Mikhail Perminov</p>
              <p class="person-function">COO & Co-Founder</p>
              <div class="person-bg">
                <span class="person-text"><span class="person-text-accent">Experience: 10+ years</span></span>
                <span class="person-text"><span class="person-text-accent">Sector: </span>Asset Management & Technology, Marketing Development</span>
                <span class="person-text"><span class="person-text-accent">Background: </span>Marketing, DeFi, IT & Software Development</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->
    <p class="mobile-roadmap-title">Road map</p>
    <section class="roadmap">
      <p class="roadmap-title">Road map</p>
    </section>
    <!--<section class="partners">
      <p class="partners-title">Advisers & Partners</p>
      <div class="partners-list">
        <img class="partner" src="./assets/partners/desktop/EMCD.svg">
        <img class="partner" src="./assets/partners/desktop/BAIKAL.svg">
        <img class="partner" src="./assets/partners/desktop/EZIL.svg">
        <img class="partner" src="./assets/partners/desktop/XDAO.svg">
        <img class="partner" src="./assets/partners/desktop/UNO.svg">
        <img class="partner-laptop" src="./assets/partners/laptop/EMCD.svg">
        <img class="partner-laptop" src="./assets/partners/laptop/BAIKAL.svg">
        <img class="partner-laptop" src="./assets/partners/laptop/EZIL.svg">
        <img class="partner-laptop" src="./assets/partners/laptop/XDAO.svg">
        <img class="partner-laptop" src="./assets/partners/laptop/UNO.svg">
      </div>
      <div class="partners-list-mobile">
        <div class="row">
          <img class="partner-mobile" src="./assets/partners/mobile/EMCD.svg">
          <img class="partner-mobile" src="./assets/partners/mobile/BAIKAL.svg">
          <img class="partner-mobile" src="./assets/partners/mobile/EZIL.svg">
        </div>
        <div class="row">
          <img class="partner-mobile" src="./assets/partners/mobile/XDAO.svg">
          <img class="partner-mobile" src="./assets/partners/mobile/UNO.svg">
        </div>
      </div>
    </section>-->
    <section class="contacts">
      <p class="contacts-title coloured">Interested in</p>
      <p class="contacts-title coloured">working with us?</p>
      <p class="contacts-title">Start a conversation now.</p>
      <p class="contacts-credential mail">hello@phyera.com</p>
      <!--<p class="contacts-credential">+1 (234) 567-8901</p>-->
      <div class="socials">
        <!--<p class="social-link">FACEBOOK</p>
        <p class="social-link">INSTAGRAM</p>
        <p class="social-link">DISCORD</p>-->
        <a href="https://t.me/phyerachat" target="_blank" class="social-link">TELEGRAM</a>
        <a href="https://www.linkedin.com/company/phyera/" target="_blank" class="social-link">LINKEDIN</a>
      </div>
      <footer>
        <div class="footer-left">
          <!--<span class="footer-btn">Privacy & Terms</span>
          <span class="footer-btn">Light paper</span>-->
        </div>
        <span class="copyright">
          © Phyera, All rights reserved, 2022
        </span>
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 2;
}

.wrapper {
  background: #EEEFF2;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.main {
  background-image: url('./assets/gfx/bg-desktop.svg');
  background-repeat: no-repeat;
  min-height: 1400px;
  width: 100%;
  background-position: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main:before {
  position: absolute;
  top: -306px;
  content: '';
  width: 749px;
  height: 664px;
  background-image: url('./assets/gfx/hollowcube-desktop.png');
  background-size: cover;
  right: 23%;
  opacity: 0.8;
  z-index: 1;
}

.main:after {
  position: absolute;
  bottom: -86px;
  right: -60px;
  width: 611px;
  height: 710px;
  background-image: url('./assets/gfx/png/Group\ 2.png');
  transform: rotate(325deg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  z-index: 1;
  filter: drop-shadow(0px 60px 90px rgba(0, 0, 0, 0.30));
}

header {
  padding: 64px;
  padding-top: 86px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.header-logo {
  padding-top: 4px;
}

.header-request {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  width: 130px;
  height: 130px;
  padding: 30px 25px;
  cursor: pointer;
  background: #99E0DA;
  text-decoration: none;
  color: #2F3339;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.main-text {
  margin-top: 124px;
  max-width: 1200px;
}

.main-text h2 {
  margin-top: 40px;
}

h1 {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #2F3339;
}

h2 {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 87%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #2F3339;
}

.whatwedo {
  margin-top: 80px;
}

.whatwedo h1 {
  margin-bottom: 32px;
}

.whatwedo h2 {
  margin-bottom: 80px;
}

.h1grey {
  color: #CACDD5;
}

.perks-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 140px;
}

.perks-group-of2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.perk {
  position: relative;
  width: 282px;
  height: 320px;
  border-radius: 44px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 24px;
  margin: 20px;
  cursor: pointer;
  box-shadow: 2px 4px 16px rgba(47, 51, 57, 0.4);
  transition: 0.4s;
  padding-bottom: 54px;
}

/*.perks-group-of2 .perk {
  padding-bottom: 54px;
}*/

.perk:hover {
  box-shadow: 2px 4px 12px rgba(47, 51, 57, 0.5);
  transition: 0.4s;
}

.perk-dark {
  background: #464A53;
  color: #FAFAFB;
}

.perk-color {
  background: #99E0DA;
  color: #3B3E46;
}

.perk-title {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
}

.perk-title-accent {
  font-weight: 700;
}

.perk-arrow {
  margin-top: 20px;
}

#perk1:before {
  position: absolute;
  right: 6px;
  top: -20px;
  content: '';
  background-image: url('./assets/gfx/png/Group\ 2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100px;
  height: 100px;
  transform: rotate(330deg)
}

#perk2:before {
  position: absolute;
  right: 20px;
  top: 20px;
  content: '';
  background-image: url('./assets/gfx/png/Group\ 2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100px;
  height: 100px;
  transform: rotate(10deg)
}

#perk3:before {
  position: absolute;
  right: 60px;
  top: 20px;
  content: '';
  background-image: url('./assets/gfx/png/Cheese\ Half\ 3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 120px;
  height: 100px;
  transform: rotate(0deg)
}

#perk4:before {
  position: absolute;
  right: -10px;
  top: -10px;
  content: '';
  background-image: url('./assets/gfx/png/Group\ 2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100px;
  height: 100px;
  transform: rotate(100deg)
}

#perk4:after {
  position: absolute;
  right: 120px;
  top: 10px;
  content: '';
  background-image: url('./assets/gfx/png/Group\ 2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100px;
  height: 100px;
  transform: rotate(150deg)
}

#perk5:before {
  position: absolute;
  right: 40px;
  top: 20px;
  content: '';
  background-image: url('./assets/gfx/png/Round\ Cube2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100px;
  height: 100px;
  transform: rotate(330deg)
}

#perk6:before {
  position: absolute;
  right: 40px;
  top: -10px;
  content: '';
  background-image: url('./assets/gfx/png/Round\ Cube2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 120px;
  height: 120px;
  transform: rotate(15deg)
}

#perk7:before {
  position: absolute;
  right: 20px;
  top: 10px;
  content: '';
  background-image: url('./assets/gfx/png/Cheese\ Half\ 2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 120px;
  height: 120px;
  transform: rotate(0deg)
}

.whatisphyera {
  padding-top: 100px;
  padding-bottom: 170px;
  min-height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.answer {
  max-width: 1180px;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 128px;
  line-height: 87%;
  letter-spacing: -0.03em;
  background: linear-gradient(74.8deg, #99E0DA 6.62%, #FCE3A7 95.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  backdrop-filter: blur(10px);
  min-height: 580px;
}

.answer-accent {
  font-weight: 700;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team h1 {
  margin-bottom: 30px;
}

.team-roster {
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  max-width: 1490px;
}

.roster-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}

.row-tilted-left {
  padding-right: 152px;
}

.row-tilted-right {
  padding-left: 152px;
}

.person-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 590px;
}

.person-card:first-child {
  margin-right: 160px;
}

.person-img {
  margin-right: 30px;
}

.person-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.person-title {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #3B3E46;
}

.person-function {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #00AEB9;
}

.person-bg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.person-text {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #464A53;
}

.person-text-accent {
  font-weight: 600;
}

.roadmap {
  min-height: 1040px;
  background-image: url('./assets/roadmap/upd-roadmap-desktop.svg');
  background-position: bottom right;
  background-repeat: no-repeat;
  padding: 200px;
  padding-top: 48px;
  padding-left: 296px;
}

.roadmap-title {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 90%;
  color: #2F3339;
}

.mobile-roadmap-title {
  display: none;
  text-align: center;
}

.partners {
  background: #2F3339;
  padding-top: 88px;
  padding-left: 191px;
  padding-right: 191px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
}

.partners-title {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 128px;
  line-height: 87%;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #F5F6F7;
  backdrop-filter: blur(10px);
  align-self: flex-start;
}

.partners-list {
  margin-top: 156px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.partner {
  margin-left: 30px;
  opacity: 0.8;
  cursor: pointer;
}

.partner-laptop {
  display: none;
}

.partners-list-mobile {
  display: none;
}

.partner:hover {
  opacity: 1;
}

.contacts {
  background: #2F3339;
  padding-top: 88px;
  padding-left: 191px;
  padding-right: 191px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.contacts-title {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 128px;
  line-height: 87%;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #F5F6F7;
  backdrop-filter: blur(10px);
}

.coloured {
  background: linear-gradient(74.8deg, #99E0DA 6.62%, #FCE3A7 95.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.coloured:nth-child(2) {
  margin-bottom: 40px;
  line-height: 118%;
}

.contacts-credential {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  color: #F5F6F7;
}

.mail {
  margin-top: 60px;
  margin-bottom: 12px;
}

.socials {
  display: flex;
  flex-direction: column;
  margin-top: 136px;
}

.social-link {
  margin-top: 16px;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 122%;
  color: #F5F6F7;
  cursor: pointer;
  opacity: 0.8;
  text-decoration: none;
}

.social-link:hover {
  opacity: 1;
}

footer {
  margin-top: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footer-btn {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9C9C9C;
  cursor: pointer;
  opacity: 0.8;
}

.footer-btn:hover {
  opacity: 1;
}

.footer-btn:first-child {
  margin-right: 30px;
}

.copyright {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: right;
  color: #F5F6F7;
  text-transform: uppercase;
}

/* LAPTOP VERSION */

@media screen and (max-width: 1680px) {
  .main {
    background: url('./assets/gfx/bg-laptop.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    min-height: 1050px;
  }

  .header-logo {
    width: 168px;
    height: 68px;
    padding-top: 12px;
  }

  .header-request {
    width: 89px;
    height: 89px;
    padding: 17px 20px;
    font-size: 13px;
  }

  .main::before {
    position: absolute;
    top: -220px;
    content: '';
    width: 635px;
    height: 563px;
    background-image: url('./assets/gfx/hollowcube-laptop.png');
    background-size: 100% 100%;
    right: 20%;
    opacity: 0.8;
    z-index: 1;
  }

  .main::after {
    position: absolute;
    bottom: -116px;
    right: -60px;
    width: 499px;
    height: 575px;
    background-image: url('./assets/gfx/png/Group\ 2.png');
    content: '';
    z-index: 1;
  }

  .main-text {
    margin-top: 160px;
  }

  h1 {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 78px;
    line-height: 87%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2F3339;
  }

  h2 {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 87%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2F3339;
  }

  .whatwedo h1 {
    margin-bottom: 28px;
  }

  .whatwedo h2 {
    margin-bottom: 72px;
  }
  
  .perk {
    width: 208px;
    height: 232px;
    border-radius: 40px;
    margin: 14px;
    padding-bottom: 32px;
  }

  /*.perks-group-of2 .perk {
    padding-bottom: 32px;
  }*/

  #perk1:before {
    position: absolute;
    right: 6px;
    top: -20px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    transform: rotate(330deg)
  }

  #perk2:before {
    position: absolute;
    right: 20px;
    top: 20px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    transform: rotate(10deg)
  }

  #perk3:before {
    position: absolute;
    right: 30px;
    top: 20px;
    content: '';
    background-image: url('./assets/gfx/png/Cheese\ Half\ 3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100px;
    height: 80px;
    transform: rotate(0deg)
  }

  #perk4:before {
    position: absolute;
    right: -10px;
    top: -10px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    transform: rotate(100deg)
  }

  #perk4:after {
    position: absolute;
    right: 80px;
    top: 10px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    transform: rotate(150deg)
  }

  #perk5:before {
    position: absolute;
    right: 20px;
    top: 10px;
    content: '';
    background-image: url('./assets/gfx/png/Round\ Cube2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 70px;
    height: 70px;
    transform: rotate(330deg)
  }

  #perk6:before {
    position: absolute;
    right: 60px;
    top: -10px;
    content: '';
    background-image: url('./assets/gfx/png/Round\ Cube2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    transform: rotate(15deg)
  }

  #perk7:before {
    position: absolute;
    right: 40px;
    top: 10px;
    content: '';
    background-image: url('./assets/gfx/png/Cheese\ Half\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    transform: rotate(0deg)
  }

  .perk-title {
    font-size: 18px;
  }

  .answer {
    font-size: 120px;
    min-height: 440px;
  }

  .team-roster {
    margin-top: 114px;
    max-width: 1140px;
  }

  .team h1 {
    margin-bottom: 28px;
  }

  .team img {
    width: 170px;
    height: 170px;
  }

  .row-tilted-left {
    padding-right: 84px;
  }

  .row-tilted-right {
    padding-left: 84px;
  }

  .person-title {
    font-size: 25px;
  }

  .person-function {
    font-size: 11px;
  }

  .person-text {
    font-size: 11px;
  }

  .roadmap {
    min-height: 800px;
    background-image: url('./assets/roadmap/upd-roadmap-laptop.svg');
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 175px;
    padding-top: 60px;
    padding-left: 150px;
  }

  .roadmap-title {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 78px;
    line-height: 90%;
    color: #2F3339;
  }

  .partners {
    padding: 175px;
    padding-top: 108px;
    padding-bottom: 86px;
  }

  .partners-title {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 87%;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #F5F6F7;
    backdrop-filter: blur(10px);
  }

  .partner {
    display: none;
  }

  .partner-laptop {
    display: inherit;
    margin-left: 24px;
    opacity: 0.8;
    cursor: pointer;
  }
  
  .partner-laptop:hover {
    opacity: 1;
  }

  .contacts {
    padding-left: 175px;
    padding-right: 175px;
  }

  .contacts-title {
    font-size: 100px;
  }

  .coloured:nth-child(2) {
    margin-bottom: 48px;
  }

  .contacts-credential {
    font-size: 24px;
  }

  .mail {
    margin-bottom: 6px;
  }

  .social-link {
    margin-top: 13px;
    font-size: 13px;
  }

  .footer {
    margin-top: 46px;
  }

  .footer-btn {
    font-size: 11px;
  }

  .copyright {
    font-size: 11px;
  }
}

@media screen and (max-width: 1020px) {
  .main {
    background-image: url('./assets/gfx/bg-mobile.svg');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    min-height: 1000px;
  }

  .main:before {
    position: absolute;
    top: -80px;
    content: '';
    width: 400px;
    height: 360px;
    background-image: url('./assets/gfx/hollowcube-laptop.png');
    right: -110px;
    opacity: 0.8;
    z-index: 1;
  }

  .main:after {
    position: absolute;
    bottom: 160px;
    right: -110px;
    width: 254px;
    height: 275px;
    background-image: url('./assets/gfx/png/Group\ 2.png');
    content: '';
    z-index: 1;
  }

  header {
    padding: 22px;
    padding-top: 36px;
    align-items: center;
  }

  .header-logo {
    width: 80px;
    height: 32px;
    padding: 0;
  }

  .header-request {
    width: 46px;
    height: 46px;
    padding: 7px 8px;
    font-size: 10px;
  }

  .request-arrow {
    width: 6px;
    height: 6px;
  }

  h1 {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 105.5%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2F3339;
  }

  h2 {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 87%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2F3339;
  }

  .whatwedo {
    margin-top: 0;
  }

  .whatwedo h1 {
    margin-bottom: 15px;
  }
  
  .whatwedo h2 {
    margin-bottom: 48px;
  }

  .perks-row {
    margin-bottom: 64px;
  }

  .perks-row-groupof4 {
    display: flex;
    flex-direction: column;
  }

  .perks-group-of2 {
    margin-bottom: 7px;
  }

  .perk {
    border-radius: 20px;
    margin: 7px;
    width: 122px;
    height: 136px;
    padding: 12px;
    padding-bottom: 16px;
  }

  /*.perks-group-of2 .perk {
    padding-bottom: 24px;
  }*/

  .perk-title {
    font-size: 12px;
  }

  #perk1:before {
    position: absolute;
    right: 6px;
    top: -10px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40px;
    height: 40px;
    transform: rotate(330deg)
  }

  #perk2:before {
    position: absolute;
    right: 20px;
    top: 10px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40px;
    height: 40px;
    transform: rotate(10deg)
  }

  #perk3:before {
    position: absolute;
    right: 30px;
    top: 20px;
    content: '';
    background-image: url('./assets/gfx/png/Cheese\ Half\ 3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 50px;
    height: 40px;
    transform: rotate(0deg)
  }

  #perk4:before {
    position: absolute;
    right: -5px;
    top: -10px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40px;
    height: 40px;
    transform: rotate(100deg)
  }

  #perk4:after {
    position: absolute;
    right: 40px;
    top: 10px;
    content: '';
    background-image: url('./assets/gfx/png/Group\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40px;
    height: 40px;
    transform: rotate(150deg)
  }

  #perk5:before {
    position: absolute;
    right: 20px;
    top: 10px;
    content: '';
    background-image: url('./assets/gfx/png/Round\ Cube2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 35px;
    height: 35px;
    transform: rotate(330deg)
  }

  #perk6:before {
    position: absolute;
    right: 20px;
    top: -5px;
    content: '';
    background-image: url('./assets/gfx/png/Round\ Cube2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 50px;
    height: 50px;
    transform: rotate(15deg)
  }

  #perk7:before {
    position: absolute;
    right: 20px;
    top: 10px;
    content: '';
    background-image: url('./assets/gfx/png/Cheese\ Half\ 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 40px;
    height: 40px;
    transform: rotate(0deg)
  }

  .perk-arrow {
    display: none;
  }

  .whatisphyera {
    padding: 0;
    margin-top: 60px;
    margin-bottom: 90px;
    min-height: 306px;
    height: 306px;
    background-image: url('./assets/new-text-as-svg.svg');
    background-size: 80% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .answer {
    /*font-size: 50px;*/
    display: none;
  }

  .team h1 {
    margin-bottom: 14px;
  }

  .team-roster {
    margin-top: 56px;
  }

  .roster-row {
    flex-direction: column;
    margin: 0;
  }

  .person-card:first-child {
    margin-right: 0;
  }

  .person-card {
    max-width: 330px;
    margin-bottom: 44px;
  }

  .person-card:nth-child(2) {
    padding-left: 24px;
  }

  .row-tilted-left, .row-tilted-right {
    padding: 0;
  }

  .person-img {
    width: 128px;
    height: 128px;
    border-radius: 40px;
    margin-right: 19px;
  }

  .person-title {
    font-size: 18px;
  }

  .person-function {
    font-size: 10px;
  }

  .person-text {
    font-size: 10px;
    line-height: 14px;
  }

  .roadmap {
    min-height: 1192px;
    background-image: url('./assets/roadmap/upd-roadmap-mobile.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    /*padding-top: 140px;*/
  }

  .roadmap-title {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 90%;
    color: #2F3339;
    display: none;
  }

  .mobile-roadmap-title {
    display: inherit;
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 90%;
    color: #2F3339;
  }

  .partners {
    padding-top: 126px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .partners-title {
    font-size: 35px;
  }

  .partners-list {
    display: none;
  }

  .partners-list-mobile {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .row:nth-child(2) {
    margin-top: 30px;
    justify-content: flex-start;
  }

  .partner-mobile {
    max-width: 90px;
    margin-right: 26px;
    cursor: pointer;
  }

  .contacts {
    padding-top: 60px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 42px;
  }

  .contacts-title {
    font-size: 32px;
  }

  .coloured:nth-child(2) {
    margin-bottom: 24px;
  }

  .contacts-credential {
    font-size: 18px;
    line-height: 100%;
  }

  .mail {
    margin-top: 40px;
    margin-bottom: 8px;
  }

  footer {
    flex-direction: column;
    justify-content: center;
  }

  .copyright {
    margin-top: 24px;
    text-align: left;
  }
}
</style>
